import { isUserLoggedIn } from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'

export default [
    {
        path: '/volunteers',
        name: 'volunteers',
        component: () => import('@/views/volunteers/Lists.vue'),
        meta: {
            pageTitle: 'Volunteers',
            resource: 'volunteers',
            action: 'read',
            breadcrumb: [
                {
                    text: 'Volunteer',
                },
                {
                    text: 'Lists',
                    active: true,
                },
            ],
            createUrl: 'create-volunteer',
            showCreateButton: true,
        },
        beforeEnter: (to, from, next) => {
            // Get logged in status
            const isLoggedIn = isUserLoggedIn()
      
            // If not logged in, then redirect to login page
            if (!isLoggedIn) {
              return next('/login')
            }
            
            // If login and not have access
            if (!canNavigate(to)) {
              return next({ name: 'misc-not-authorized' })
            }
      
            return next()
        },
    },
    {
        path: '/volunteer/view/:id',
        name: 'view-volunteer',
        component: () => import('@/views/volunteers/View.vue'),
        meta: {
            pageTitle: 'Volunteers',
            resource: 'volunteers',
            action: 'view',
            breadcrumb: [
                {
                    text: 'Volunteer',
                },
                {
                    text: 'View',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            // Get logged in status
            const isLoggedIn = isUserLoggedIn()
      
            // If not logged in, then redirect to login page
            if (!isLoggedIn) {
              return next('/login')
            }
            
            // If login and not have access
            if (!canNavigate(to)) {
              return next({ name: 'misc-not-authorized' })
            }
      
            return next()
        },
    },
    {
        path: '/volunteer/create',
        name: 'create-volunteer',
        component: () => import('@/views/volunteers/Create.vue'),
        meta: {
            pageTitle: 'Add Volunteer',
            resource: 'volunteers',
            action: 'create',
            requiresAuth: true,
            breadcrumb: [
                {
                    text: 'Volunteers',
                },
                {
                    text: 'Create',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            // Get logged in status
            const isLoggedIn = isUserLoggedIn()
      
            // If not logged in, then redirect to login page
            if (!isLoggedIn) {
              return next('/login')
            }
            
            // If login and not have access
            if (!canNavigate(to)) {
              return next({ name: 'misc-not-authorized' })
            }
      
            return next()
        },
    },
    {
        path: '/volunteer/edit/:id',
        name: 'edit-volunteer',
        component: () => import('@/views/volunteers/Edit.vue'),
        meta: {
            pageTitle: 'Edit Volunteer',
            resource: 'volunteers',
            action: 'edit',
            requiresAuth: true,
            breadcrumb: [
                {
                    text: 'Volunteers',
                },
                {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            // Get logged in status
            const isLoggedIn = isUserLoggedIn()
      
            // If not logged in, then redirect to login page
            if (!isLoggedIn) {
              return next('/login')
            }
            
            // If login and not have access
            if (!canNavigate(to)) {
              return next({ name: 'misc-not-authorized' })
            }
      
            return next()
        },
    },
]