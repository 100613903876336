import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import usersAndRoles from './routes/users-and-roles'
import servolution from './routes/servolution'
import volunteers from './routes/volunteers'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    { path: '/auth/mygms/callback' },
    ...apps,
    ...dashboard,
    ...usersAndRoles,
    ...servolution,
    ...volunteers,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// router.beforeEach((to, _, next) => {
//   next()
  // const isLoggedIn = isUserLoggedIn()
  // const can = canNavigate(to)

  // if (to.path === '/auth/mygms/callback') {
  //   console.log('callback')
  //   return next()
  // }
  
  // if (!isLoggedIn) {
  //   console.log('login')
  //   next('/login')
  // } else if (isLoggedIn && !can) {
  //   console.log('not authorized')
  //   next({ name: 'misc-not-authorized' })
  // }

  // return next()

  // console.log(to.path)
  
  // const userData = Cookies.get('userData') ? JSON.parse(CryptoJS.AES.decrypt(Cookies.get('userData'), process.env.VUE_APP_COOKIE_SECRET).toString(CryptoJS.enc.Utf8)) : null
  // const existingAbility = (userData && !(lodash.isNull(userData.roles) || lodash.isUndefined(userData.roles))) ? userData.roles[0].permissions : null
  // if (lodash.isNull(existingAbility)) {
  //   if (!isLoggedIn) {
  //     return next('/login')
  //   }
  // }

  // Callback
  // Check if not logged in
  // if (!can) {
  //   // Redirect to login if not logged in
  //   if (!isLoggedIn) {
  //     console.log('login')
  //     next('/login')
  //   }

  //   // If not authorized
  //   console.log('misc')
  //   next({ name: 'misc-not-authorized' })
  // }

  // return next()

  // if (to.path === '/auth/mygms/callback') {
  //   return next()
  // }

  // console.log(canNavigate(to))

  // if (!canNavigate(to)) {
  //   if (!isLoggedIn) {
  //     // Redirect to oauth page
  //     window.location.href = `${process.env.VUE_APP_MYGMS_OAUTH_DOMAIN}/oauth/authorize?${stringify(
  //       {
  //         response_type: 'code',
  //         client_id: process.env.VUE_APP_MYGMS_OAUTH_CLIENT_ID,
  //         scope: process.env.VUE_APP_MYGMS_OAUTH_SCOPE,
  //         state: '1',
  //         redirect_uri: process.env.VUE_APP_MYGMS_OAUTH_CALLBACK_URL,
  //       },
  //     )}`
  //   }

  //   next({ name: 'misc-not-authorized' })
  // }

  // If user is not logged in
  // if (!isLoggedIn) {
  //   // Check if it's the callback url
  //   if (to.path === '/auth/mygms/callback') {
  //     const { code } = to.query

      // axios.get(`${process.env.VUE_APP_MYGMS_PERSONAL_API}`, {
      //   headers: {
      //     Authorization: `Bearer ${code}`,
      //   },
      // }).then(account => {
      //   axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/authenticate?mygmsid=${account.data.result.mygms_id}`)
      //     .then(result => {
      //       // Set jwt for token
      //       jwt.setToken(result.data.token)

      //       // User data
      //       const { user, isFirstRegistration } = result.data

      //       // Set cookie for user data
      //       const encryptedCookies = CryptoJS.AES.encrypt(JSON.stringify(user), process.env.VUE_APP_COOKIE_SECRET).toString()
      //       Cookies.set('userData', encryptedCookies, { expires: new Date(new Date().getTime() + 60 * 60 * 1000) })

      //       // If first registration
      //       if (isFirstRegistration) {
      //         next({ name: 'misc-not-authorized' })
      //       }

      //       // Update ability
      //       ability.update(user.roles[0].permissions)

      //       // If no access
      //       if (!canNavigate(to)) {
      //         return next({ name: 'misc-not-authorized' })
      //       }

      //       // Redirect
      //       next('/dashboard')
      //     })
      // })
  //   } else {
  //     // Redirect to oauth page
  //     window.location.href = `${process.env.VUE_APP_MYGMS_OAUTH_DOMAIN}/oauth/authorize?${stringify(
  //       {
  //         response_type: 'code',
  //         client_id: process.env.VUE_APP_MYGMS_OAUTH_CLIENT_ID,
  //         scope: process.env.VUE_APP_MYGMS_OAUTH_SCOPE,
  //         state: '1',
  //         redirect_uri: process.env.VUE_APP_MYGMS_OAUTH_CALLBACK_URL,
  //       },
  //     )}`
  //   }
  // }

  // if (!canNavigate(to)) {
  //   next({ name: 'misc-not-authorized' })
  // }

  // return next()

  // Check for callback
  // if (to.query.code) {
  //   const { code } = to.query
  //   console.log(code)

  //   axios.get(`${process.env.VUE_APP_MYGMS_PERSONAL_API}`, {
  //     headers: {
  //       Authorization: `Bearer ${code}`,
  //     },
  //   }).then(account => {
  //     axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/authenticate?mygmsid=${account.data.result.mygms_id}`)
  //       .then(result => {
  //         // Set jwt for token
  //         jwt.setToken(result.data.token)

  //         // Set cookie for user data
  //         const encryptedCookies = CryptoJS.AES.encrypt(JSON.stringify(result.data.user), process.env.VUE_APP_COOKIE_SECRET).toString()
  //         Cookies.set('userData', encryptedCookies, { expires: new Date(new Date().getTime() + 60 * 60 * 1000) })

  //         // If no access
  //         if (!canNavigate(to)) {
  //           return next({ name: 'misc-not-authorized' })
  //         }

  //         // Redirect
  //         return next({ name: 'dashboard' })
  //       })
  //   })
  // }

  // If not logged in
  // if (!isLoggedIn) {
  //   window.location.href = `${process.env.VUE_APP_MYGMS_OAUTH_DOMAIN}/oauth/authorize?${stringify(
  //     {
  //       response_type: 'code',
  //       client_id: process.env.VUE_APP_MYGMS_OAUTH_CLIENT_ID,
  //       scope: process.env.VUE_APP_MYGMS_OAUTH_SCOPE,
  //       state: '1',
  //       redirect_uri: process.env.VUE_APP_MYGMS_OAUTH_CALLBACK_URL,
  //     },
  //   )}`
  // }

  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  //   next('/dashboard')
  // }

  // return next()

  // if (!canNavigate(to)) {
  //   console.log('can navigate')
  //   if (!isLoggedIn) {
  //     window.location.href = `${process.env.VUE_APP_MYGMS_OAUTH_DOMAIN}/oauth/authorize?${stringify(
  //       {
  //         response_type: 'code',
  //         client_id: process.env.VUE_APP_MYGMS_OAUTH_CLIENT_ID,
  //         scope: process.env.VUE_APP_MYGMS_OAUTH_SCOPE,
  //         state: '1',
  //         redirect_uri: process.env.VUE_APP_MYGMS_OAUTH_CALLBACK_URL,
  //       },
  //     )}`
  //   }

  //   // If logged in => not authorized
  //   return next({ name: 'misc-not-authorized' })
  // }

  // Redirect if logged in
  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  //   next('/dashboard')
  // }

  // return next()

  // Check for the first authentication
  // if (to.matched.some(record => record.meta.requiresAuth)) {
  //   const isLoggedIn = isUserLoggedIn()
    
  //   // Check if user is logged in
  //   if (!isLoggedIn) {
  //     window.location.href = `${process.env.VUE_APP_MYGMS_OAUTH_DOMAIN}/oauth/authorize?${stringify(
  //       {
  //         response_type: 'code',
  //         client_id: process.env.VUE_APP_MYGMS_OAUTH_CLIENT_ID,
  //         scope: process.env.VUE_APP_MYGMS_OAUTH_SCOPE,
  //         state: '1',
  //         redirect_uri: process.env.VUE_APP_MYGMS_OAUTH_CALLBACK_URL,
  //       },
  //     )}`
  //   } else if (!canNavigate(to)) {
  //     return next({ name: 'misc-not-authorized' })
  //   } else {
  //     return next()
  //   }
  // } else {
  //   // callback
  //   console.log(to.path)
  //   // const { code } = to.query

  //   // axios.get(`${process.env.VUE_APP_MYGMS_PERSONAL_API}`, {
  //   //   headers: {
  //   //     Authorization: `Bearer ${code}`,
  //   //   },
  //   // }).then(account => {
  //   //   axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/authenticate?mygmsid=${account.data.result.mygms_id}`)
  //   //     .then(result => {
  //   //       // Set jwt for token
  //   //       jwt.setToken(result.data.token)

  //   //       // Set cookie for user data
  //   //       const encryptedCookies = CryptoJS.AES.encrypt(JSON.stringify(result.data.user), process.env.VUE_APP_COOKIE_SECRET).toString()
  //   //       Cookies.set('userData', encryptedCookies, { expires: new Date(new Date().getTime() + 60 * 60 * 1000) })

  //   //       // If no access
  //   //       if (!canNavigate(to)) {
  //   //         return next({ name: 'misc-not-authorized' })
  //   //       }

  //   //       // Redirect
  //   //       return next({ name: 'dashboard' })
  //   //     })
  //   // })
  // }

  // If no access
  // if (!canNavigate(to)) {
  //   return next({ name: 'misc-not-authorized' })
  // }

  // // Redirect
  // return next({ name: 'dashboard' })
// })

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
