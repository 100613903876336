// import Cookies from 'js-cookie'
// import CryptoJS from 'crypto-js'
// import axios from 'axios'
// import jwt from '@/auth/jwt/jwt'
// import ability from '@/libs/acl/ability'
import { isUserLoggedIn } from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'

export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      resource: 'dashboard',
      action: 'read',
      requiresAuth: true,
      userName: 'test',
      // redirectIfLoggedIn: true,
    },
    beforeEnter: (to, from, next) => {
      // Get logged in status
      const isLoggedIn = isUserLoggedIn()

      // If not logged in, then redirect to login page
      if (!isLoggedIn) {
        return next('/login')
      }
      
      // // If login and not have access
      if (!canNavigate(to)) {
        return next({ name: 'misc-not-authorized' })
      }

      return next()
    },
  },
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  },
  {
    path: '/dashboard/ecommerce',
    name: 'dashboard-ecommerce',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  },
]
