import { isUserLoggedIn } from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'

export default [
    {
        path: '/users',
        name: 'users',
        component: () => import('@/views/users-and-roles/users/Lists.vue'),
        meta: {
            pageTitle: 'Users',
            resource: 'user',
            action: 'read',
            requiresAuth: true,
            breadcrumb: [
                {
                    text: 'Users',
                    active: true,
                },
            ],
            createUrl: 'create-user',
            showCreateButton: true,
        },
        beforeEnter: (to, from, next) => {
            // Get logged in status
            const isLoggedIn = isUserLoggedIn()
      
            // If not logged in, then redirect to login page
            if (!isLoggedIn) {
              return next('/login')
            }
            
            // If login and not have access
            if (!canNavigate(to)) {
              return next({ name: 'misc-not-authorized' })
            }
      
            return next()
        },
    },
    {
        path: '/user/create',
        name: 'create-user',
        component: () => import('@/views/users-and-roles/users/Create.vue'),
        meta: {
            pageTitle: 'Create User',
            resource: 'user',
            action: 'create',
            requiresAuth: true,
            breadcrumb: [
                {
                    text: 'Users',
                },
                {
                    text: 'Create',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            // Get logged in status
            const isLoggedIn = isUserLoggedIn()
      
            // If not logged in, then redirect to login page
            if (!isLoggedIn) {
              return next('/login')
            }
            
            // If login and not have access
            if (!canNavigate(to)) {
              return next({ name: 'misc-not-authorized' })
            }
      
            return next()
        },
    },
    {
        path: '/user/edit/:id',
        name: 'edit-user',
        component: () => import('@/views/users-and-roles/users/Edit.vue'),
        meta: {
            pageTitle: 'Edit User',
            resource: 'user',
            action: 'edit',
            requiresAuth: true,
            breadcrumb: [
                {
                    text: 'Users',
                },
                {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            // Get logged in status
            const isLoggedIn = isUserLoggedIn()
      
            // If not logged in, then redirect to login page
            if (!isLoggedIn) {
              return next('/login')
            }
            
            // If login and not have access
            if (!canNavigate(to)) {
              return next({ name: 'misc-not-authorized' })
            }
      
            return next()
        },
    },
    {
        path: '/roles',
        name: 'roles',
        component: () => import('@/views/users-and-roles/roles/Lists.vue'),
        meta: {
            pageTitle: 'Roles',
            resource: 'role',
            action: 'read',
            requiresAuth: true,
            breadcrumb: [
                {
                    text: 'Roles',
                    active: true,
                },
            ],
            createUrl: 'create-role',
            showCreateButton: true,
        },
        beforeEnter: (to, from, next) => {
            // Get logged in status
            const isLoggedIn = isUserLoggedIn()
      
            // If not logged in, then redirect to login page
            if (!isLoggedIn) {
              return next('/login')
            }
            
            // If login and not have access
            if (!canNavigate(to)) {
              return next({ name: 'misc-not-authorized' })
            }
      
            return next()
        },
    },
    {
        path: '/role/create',
        name: 'create-role',
        component: () => import('@/views/users-and-roles/roles/Create.vue'),
        meta: {
            pageTitle: 'Create Role',
            resource: 'role',
            action: 'create',
            requiresAuth: true,
            breadcrumb: [
                {
                    text: 'Roles',
                },
                {
                    text: 'Create',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            // Get logged in status
            const isLoggedIn = isUserLoggedIn()
      
            // If not logged in, then redirect to login page
            if (!isLoggedIn) {
              return next('/login')
            }
            
            // If login and not have access
            if (!canNavigate(to)) {
              return next({ name: 'misc-not-authorized' })
            }
      
            return next()
        },
    },
    {
        path: '/role/edit/:id',
        name: 'edit-role',
        component: () => import('@/views/users-and-roles/roles/Edit.vue'),
        meta: {
            pageTitle: 'Edit Role',
            resource: 'role',
            action: 'edit',
            requiresAuth: true,
            breadcrumb: [
                {
                    text: 'Roles',
                },
                {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            // Get logged in status
            const isLoggedIn = isUserLoggedIn()
      
            // If not logged in, then redirect to login page
            if (!isLoggedIn) {
              return next('/login')
            }
            
            // If login and not have access
            if (!canNavigate(to)) {
              return next({ name: 'misc-not-authorized' })
            }
      
            return next()
        },
    },
    {
        path: '/tlcoach',
        name: 'tlcoach',
        component: () => import('@/views/users-and-roles/tlcoach/Lists.vue'),
        meta: {
            pageTitle: 'TL-Coach',
            resource: 'tlcoach',
            action: 'read',
            requiresAuth: true,
            breadcrumb: [
                {
                    text: 'TL-Coach',
                    active: true,
                },
            ],
            createUrl: 'create-tl-coach',
            showCreateButton: true,
        },
        beforeEnter: (to, from, next) => {
            // Get logged in status
            const isLoggedIn = isUserLoggedIn()
      
            // If not logged in, then redirect to login page
            if (!isLoggedIn) {
              return next('/login')
            }
            
            // If login and not have access
            if (!canNavigate(to)) {
              return next({ name: 'misc-not-authorized' })
            }
      
            return next()
        },
    },
    {
        path: '/tlcoach/create',
        name: 'create-tl-coach',
        component: () => import('@/views/users-and-roles/tlcoach/Create.vue'),
        meta: {
            pageTitle: 'Create TL-Coach',
            resource: 'tlcoach',
            action: 'create',
            requiresAuth: true,
            breadcrumb: [
                {
                    text: 'TL-Coach',
                },
                {
                    text: 'Create',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            // Get logged in status
            const isLoggedIn = isUserLoggedIn()
      
            // If not logged in, then redirect to login page
            if (!isLoggedIn) {
              return next('/login')
            }
            
            // If login and not have access
            if (!canNavigate(to)) {
              return next({ name: 'misc-not-authorized' })
            }
      
            return next()
        },
    },
    {
        path: '/tlcoach/edit/:id',
        name: 'edit-tl-coach',
        component: () => import('@/views/users-and-roles/tlcoach/Edit.vue'),
        meta: {
            pageTitle: 'Edit TL-Coach',
            resource: 'tlcoach',
            action: 'edit',
            requiresAuth: true,
            breadcrumb: [
                {
                    text: 'TL-Coach',
                },
                {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            // Get logged in status
            const isLoggedIn = isUserLoggedIn()
      
            // If not logged in, then redirect to login page
            if (!isLoggedIn) {
              return next('/login')
            }
            
            // If login and not have access
            if (!canNavigate(to)) {
              return next({ name: 'misc-not-authorized' })
            }
      
            return next()
        },
    },
]